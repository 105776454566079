import {cva} from 'class-variance-authority';
import cn from 'classnames';

import {ShoppingBagOnlyLogo} from '@/components/brochureV2/section/Header/svg/ShopifyLogo';
import ButtonGroup from '@/components/base/modules/ButtonGroup/ButtonGroup';
import useGlobalNav from '@/hooks/navigation/global';
import useSignup from '@/hooks/useSignup';
import {useSiteData} from '@/hooks/useSiteData';
import {twMerge} from '@/stylesheets/twMerge';
import type {LinkComponent} from '@/types';

import {DesktopNavigation} from './components/DesktopNavigation';
import {GlobalNavWrapper} from './components/GlobalNavWrapper';
import {MobileNavigation} from './components/MobileNavigation';

const styles = cva(
  'flex items-center gap-x-8 w-full h-14 sm:h-global-header box-border',
  {
    variants: {
      mode: {
        light: 'text-black',
        dark: 'text-white',
      },
    },
    defaultVariants: {
      mode: 'light',
    },
  },
);

export interface SecondaryNavigationType {
  links: LinkComponent[];
  moreText: string;
  pageTitle: string;
  sectionTitle: string;
  sectionOverviewUrl: string;
}

export interface SecondaryNavigationProps {
  className?: string;
  mode?: 'light' | 'dark';
  secondaryNav?: SecondaryNavigationType;
  showGlobals?: boolean;
  homeUrl?: string;
  navigationSearch?: React.ReactNode;
  customDesktopCtaLinklist?: JSX.Element | null;
}

export default function SecondaryNavigation({
  className,
  mode = 'light',
  secondaryNav,
  showGlobals = false,
  navigationSearch = null,
  homeUrl,
  customDesktopCtaLinklist,
}: SecondaryNavigationProps) {
  const {canonicalUrl} = useSiteData();
  const globalNav = useGlobalNav();
  const {signupUrl} = useSignup();
  const path = new URL(canonicalUrl as string, 'https://www.shopify.com')
    .pathname;
  const componentName = 'secondary-navigation';

  if (!secondaryNav?.links) {
    return null;
  }

  return (
    <div
      data-component-name={componentName}
      className={twMerge(styles({mode}), className)}
    >
      <GlobalNavWrapper showGlobals={showGlobals}>
        <a href={homeUrl || globalNav.homeUrl} data-component-name="logo-home">
          <ShoppingBagOnlyLogo className="w-8 h-9" />
        </a>
      </GlobalNavWrapper>
      <MobileNavigation
        sectionTitle={secondaryNav.sectionTitle}
        sectionOverviewUrl={secondaryNav.sectionOverviewUrl}
        pageTitle={secondaryNav.pageTitle}
        links={secondaryNav.links}
        mode={mode}
        navigationSearch={navigationSearch}
      />
      <DesktopNavigation
        sectionTitle={secondaryNav.sectionTitle}
        sectionOverviewUrl={secondaryNav.sectionOverviewUrl}
        links={secondaryNav.links}
        mode={mode}
        path={path}
      />
      {navigationSearch && (
        <div className="ml-auto hidden lg:block">{navigationSearch}</div>
      )}
      <GlobalNavWrapper
        className={cn(
          'items-center gap-x-8 pl-8 app-signup-links',
          {'ml-auto': !navigationSearch},
          {
            'relative before:h-[22px] before:bg-shade-30 before:w-[1px] before:absolute before:left-0 before:top-[1/2] before:translate-[-1/2]':
              Boolean(navigationSearch),
          },
        )}
        showGlobals={showGlobals}
      >
        {customDesktopCtaLinklist ? (
          <ul className="flex items-center">{customDesktopCtaLinklist}</ul>
        ) : (
          <>
            <a href={globalNav.loginUrl} className="whitespace-nowrap">
              {globalNav.loginText}
            </a>
            <ButtonGroup
              size="small"
              mode={mode}
              buttons={[
                {
                  text: globalNav.signupText,
                  href: signupUrl,
                },
              ]}
            />
          </>
        )}
      </GlobalNavWrapper>
    </div>
  );
}
